#contact {
  padding: 10px;
  margin-bottom: 2em;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 50%;

  #contactForm {
    margin-right: auto;
    margin-left: auto;
    width: 50%;
  }

  #alert {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  input, textarea {
    border: 1px solid black;
    border-radius: 0;
  }

  button {
    border-radius: 0;
  }
}

@media (max-width: 800px) {
  #contact {
    width: 90%;
    margin-right: auto;
    margin-left: auto;

    #contactForm {
      margin-right: auto;
      margin-left: auto;
      width: 90%;
    }
    
    #alert {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}