/* Desktop */
@media only screen and (min-width: 1000px) {
  #homepage {
    text-align: center;
    margin-top: 6rem;
    h1 {
      letter-spacing: 15px;
    }
    #homeImage {
      display: none;
    }
  }
}


/* Mobile */
@media only screen and (max-width: 1000px) {
  #homepage {
    text-align: center;

    #homeImage {
      width: 80%;
    }
  }
}