@font-face {
  font-family: 'AmericanTypewriter';
  src: url('./fonts/American Typewriter Regular.ttf'); /* IE9 Compat Modes */
  src: url('./fonts/American Typewriter Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}

* {
  font-family: AmericanTypewriter;
}

/* Desktop */
@media only screen and (min-width: 1000px) {
  h1 {
    font-size: 52pt;
  }

  h2 {
    font-size: 20pt;
  }

  p {
    font-size: 15pt;
  }

  #content {
    min-height: calc(100vh - 410px);
  }
}

/* Mobile */
@media only screen and (max-width: 1000px) {
  #content {
    padding: 10px;
    min-height: calc(100vh - 100px);
  }
}