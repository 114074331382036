/* Desktop */
@media only screen and (min-width: 1000px) {
  #nav {
    text-align: center;
    width: 100%;
    position: relative;

    nav:before {
      content: '';
      position: absolute;
      top: 46%;
      left: 0;
      border-top: 2.5px solid black;
      background: black;
      width: 100%;
      transform: translateY(-46%);
      z-index: -10;
    }

    nav {
      display: flex;
      justify-content: space-between;
      position: relative;

      ul {
        margin-left: auto;
        margin-right: auto;

        li {
          display: inline;
          padding: 15px;
          a {
            color: black;
            background-color: white;
            padding: 5px;
            font-size: 20pt;
            text-decoration: none;
          }
        }

        li:nth-child(2) {
          margin-right: 125px;
          margin-left: 30px;
        }

        li:nth-child(4) {
          margin-right: 30px;
          margin-left: 125px;
        }
      }
      img {
        height: 15rem;
        background-color: white;
      }

      h1 {
        opacity: 0;
        display: inline;
        top: 0;
        -webkit-transition: opacity 1s ease-in-out;
        -moz-transition: opacity 1s ease-in-out;
        -o-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;
        .transparent {
          top: 0;
          display: inline;
          opacity: 1;
        } 
      }
    }
    #mobileNavButton, .buttonContainer, .buttonContainer .change, #logo {
      display: none;
    }

    .selected {
      background-color: black;
      color: white;
      padding: 3px 5px;
    }
  }
}


/* Mobile */
@media only screen and (max-width: 1000px) {
  #nav {
    width: 100%;
    min-height: 60px;
    border-bottom: 2px solid black;
    text-align: right;
    padding-right: 10px;
    padding-top: 10px;
    // padding-bottom: 10px;

    #logo {
      text-align: center;
      margin-bottom: -50px;
      a {
        color: black;
        text-decoration: none;
      }
    }

    .buttonContainer {
      display: inline-block;
      cursor: pointer;
    }
    
    .bar1, .bar2, .bar3 {
      width: 35px;
      height: 5px;
      background-color: #333;
      margin: 6px 0;
      transition: 0.4s;
    }
    
    .change .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
    }
    
    .change .bar2 {opacity: 0;}
    
    .change .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
    }

    nav {
      transition: all 500ms ease-in-out;
      // margin-top: 0;
      opacity: 1;

      ul {
        list-style-type: none;
        img {
          display: none;
        }
        li a {
          text-decoration: none;
          color: black;
          padding-bottom: 10px;
          padding-right: 6px;
          font-size: 1.5em;
        }
      }
    }
  }

  #nav.closed{
    nav {
      // transform: translateY(-150%);
      overflow: hidden;
      margin-top: -200px;
      opacity: 0;
    }
  }
}