/* Desktop */
@media only screen and (min-width: 1000px) {
  #about {
    width: 40%;
    margin-left: 25%;
    margin-top: 3rem;
  }
}


/* Mobile */
@media only screen and (max-width: 1000px) {

}