/* Desktop */
@media only screen and (min-width: 1000px) {
  #gallery {
    margin-top: 5rem;
    text-align: center;

    h1 {
      font-size: 38pt;
    }
  }
}


/* Mobile */
@media only screen and (max-width: 1000px) {

}